import React, { useState } from "react"
import { useAuth } from "@auth/useAuth"
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"
import { toast } from "react-toastify"
import timeZones from "./timeZones"

const SetAvailabilityTemplate = ({
  calendar = {},
  back = () => null,
  deleteCalendar = () => null,
  refresh = () => null,
}) => {
  const { makeRequest } = useAuth() || {}

  const [availability, setAvailability] = useState(
    calendar.available_times || []
  )

  const [newAvailability, setNewAvailability] = useState(
    calendar.available_times || []
  )

  const [dates, setDates] = useState(calendar.available_dates || 1)
  const [duration, setDuration] = useState(calendar.duration || 30)
  const [notice, setNotice] = useState(calendar.notice || 24)
  const [buffer, setBuffer] = useState(calendar.buffer || 0)
  const [tz, setTz] = useState(calendar.time_zone || "America/Toronto")
  const [name, setName] = useState(calendar.name || "Default")
  const [progress, setP] = useState(false)

  const saveData = async () => {
    if (!calendar.id) {
      return
    }
    setP(true)
    try {
      const res = await makeRequest("calendars/update", {
        calendarId: calendar.id,
        time_zone: tz,
        dates,
        availability,
        duration,
        notice,
        buffer,
        name,
      })
      toast.success("Calendar updated successfully.")
      refresh()
    } catch (error) {
      console.log(error)
      toast.error("Failed to add the calendar. Please retry.")
    }
    setP(false)
  }

  const updateTime = (day, label, value) => {
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ]
    const newValues = availability
    const modifiedValue = newValues.splice(
      newValues.indexOf(newValues.find((item) => item.day === day)),
      1
    )
    const val = modifiedValue[0]
    const final = [...newValues, { ...val, [label]: value }]
    setAvailability(
      final.sort(function (a, b) {
        return days.indexOf(a.day) - days.indexOf(b.day)
      })
    )
  }

  return (
    <>
      <button className={"btn btn-default btn-calendar-back"} onClick={back}>
        <img
          src={require("../../images/icons/chevron-left.svg").default}
          alt=""
        />{" "}
        Back
      </button>
      <h3 className={"mt-4 mb-5"}>Selected calendar: {name || "Default"}</h3>
      <Row>
        <Col lg={7}>
          <div className={"mb-3"}>
            <span className="admin-section admin-time-zone">
              <Input
                type="select"
                className={"col-sm-12"}
                value={tz}
                onChange={(e) => setTz(e.target.value)}
              >
                {timeZones.map((tz) => (
                  <option value={tz.value} key={tz.value}>
                    {tz.label} ({tz.value})
                  </option>
                ))}
              </Input>
            </span>
          </div>

          <div className={"mb-3"}>
            <span className="admin-section name-field">
              <Input
                type="text"
                className={"col-sm-12"}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </span>
          </div>

          <h4>When are you available to do tours?</h4>
          <p className={"my-3 py-1"}>
            Choose the times of day you'll accept tours
          </p>

          <div className="d-flex flex-column">
            {availability.length > 0 &&
              availability.map((item) => {
                return (
                  <div
                    key={item.day}
                    className={`d-flex align-items-center justify-content-between mb-3`}
                  >
                    <Input readOnly value={item.day} className={"col-sm-3"} />
                    from
                    <Input
                      type="select"
                      className={"col-sm-3"}
                      onChange={(e) =>
                        updateTime(item.day, "from", e.target.value)
                      }
                      defaultValue={item.from}
                    >
                      {timeSlots.map((time, idx) => (
                        <option value={time} key={idx}>
                          {time}
                        </option>
                      ))}
                    </Input>
                    to
                    <Input
                      type="select"
                      className={"col-sm-3"}
                      onChange={(e) =>
                        updateTime(item.day, "to", e.target.value)
                      }
                      defaultValue={item.to}
                    >
                      {timeSlots.map((time, idx) => (
                        <option value={time} key={idx}>
                          {time}
                        </option>
                      ))}
                    </Input>
                  </div>
                )
              })}
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={5} className={"mt-5"}>
          <Select
            label={"When can a tour be scheduled?"}
            options={[
              { value: 1, label: "This week and next week" },
              { value: 2, label: "3 weeks" },
              { value: 3, label: "4 weeks" },
            ]}
            defaultValue={"This week and next week"}
            value={dates}
            onChange={(e) => setDates(e.target.value)}
          />
          <Select
            label={"How long do tours last?"}
            options={[
              { value: 30, label: "30 minutes" },
              { value: 60, label: "60 minutes" },
            ]}
            defaultValue={"30 minutes"}
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
          />
          <Select
            label={"Minimum notice amount of time a tour can be booked"}
            options={[
              { value: 2, label: "2 hours" },
              { value: 3, label: "3 hours" },
              { value: 4, label: "4 hours" },
              { value: 5, label: "5 hours" },
              { value: 6, label: "6 hours" },
              { value: 24, label: "1 day" },
              { value: 48, label: "2 days" },
            ]}
            defaultValue={"1 day"}
            value={notice}
            onChange={(e) => setNotice(e.target.value)}
          />
          <Select
            label={
              "Buffer time. Padding around your meetings you can’t be booked for"
            }
            options={[
              { value: 0, label: "0 minutes" },
              { value: 30, label: "30 minutes" },
              { value: 60, label: "1 hour" },
            ]}
            defaultValue={"30 minutes"}
            value={buffer}
            onChange={(e) => setBuffer(e.target.value)}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Button
            color={"primary"}
            className={"mt-3"}
            onClick={saveData}
            disabled={progress}
          >
            Save
          </Button>
          <Button color={"secondary"} className={"mt-3 ml-3"} onClick={back}>
            Back to calendars
          </Button>
        </Col>
      </Row>

      <RemoveCalendar calendar={calendar} />
    </>
  )
}

const Select = ({ label, options, value = null, onChange }) => (
  <FormGroup>
    {label && <Label>{label}</Label>}
    <Input type="select" value={value} onChange={onChange}>
      {options.map(({ value, label }, idx) => (
        <option value={value} key={idx}>
          {label}
        </option>
      ))}
    </Input>
  </FormGroup>
)

const RemoveCalendar = ({ calendar }) => {
  const { makeRequest } = useAuth() || {}
  const removeCalendar = async () => {
    const res = await makeRequest("calendars/delete", {
      id: calendar.id,
    })
    if (typeof window !== "undefined") {
      window.location.reload()
    }
  }
  return (
    <Row>
      <Col>
        <hr className={"my-5"} />
        <Button color={"danger"} onClick={removeCalendar}>
          Remove Calendar
        </Button>
      </Col>
    </Row>
  )
}

const timeSlots = [
  // "0:00 am",
  // "0:30 am",
  // "1:00 am",
  // "1:30 am",
  // "2:00 am",
  // "2:30 am",
  // "3:00 am",
  // "3:30 am",
  // "4:00 am",
  // "4:30 am",
  // "5:00 am",
  // "5:30 am",
  "6:00 am",
  "6:30 am",
  "7:00 am",
  "7:30 am",
  "8:00 am",
  "8:30 am",
  "9:00 am",
  "9:30 am",
  "10:00 am",
  "10:30 am",
  "11:30 am",
  "12:00 pm",
  "1:00 pm",
  "1:30 pm",
  "2:00 pm",
  "2:30 pm",
  "3:00 pm",
  "3:30 pm",
  "4:00 pm",
  "4:30 pm",
  "5:00 pm",
  "5:30 pm",
  "6:00 pm",
  // "6:30 pm",
  // "7:00 pm",
  // "7:30 pm",
  // "8:00 pm",
  // "8:30 pm",
  // "9:00 pm",
  // "9:30 pm",
  // "10:00 pm",
  // "10:30 pm",
  // "11:00 pm",
  // "11:30 pm",
]

export default SetAvailabilityTemplate
