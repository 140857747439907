export default [
  {
    label: "Mountain Time",
    value: "America/Edmonton",
  },
  {
    label: "Pacific Time",
    value: "America/Vancouver",
  },
  {
    label: "Eastern Time",
    value: "America/Toronto",
  },
]
