import React, { useEffect, useState } from "react"
import Layout from "@components/layout"
import "./styles.less"
import { useAuth } from "@auth/useAuth"
import AddCalendar from "./AddCalendar"
import SetAvailability from "./SetAvailability"
import AvailableCalendars from "./AvailableCalendars"
import { toast } from "react-toastify"

const CalendarAvailabilityTemplate = () => {
  const { makeRequest, isAuthenticated } = useAuth() || {}
  const [availableCalendars, setAvailableCalendars] = useState(null)

  // fetch calendars
  const fetchCalendars = async () => {
    try {
      const res = await makeRequest("calendars/list")
      setAvailableCalendars(
        res.calendars.sort((a, b) => a.name.localeCompare(b.name))
      )
    } catch (error) {
      console.log(error)
      toast.error("Failed to list the calendars. Please contact administrator.")
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      fetchCalendars()
    }
  }, [isAuthenticated])

  return (
    <Layout title={"Calendar availability"}>
      {availableCalendars === null ? (
        <Loading />
      ) : (
        <PageLoaded
          availableCalendars={availableCalendars}
          refresh={fetchCalendars}
        />
      )}
    </Layout>
  )
}

const Loading = () => <p>Loading</p>

const PageLoaded = ({ availableCalendars = [], refresh = () => null }) => {
  const { makeRequest, isAuthenticated } = useAuth() || {}

  const [selectedCalendar, setSc] = useState({})

  const deleteCalendar = async (id) => {
    // delete calendar
    try {
      const res = await makeRequest("calendars/delete", { id })
      if (typeof window !== "undefined") {
        window.location.reload()
      }
    } catch (error) {
      console.log(error)
      toast.error("Couldn't delete your calendar. Please retry. Please retry.")
    }
  }
  return (
    <>
      {selectedCalendar.id ? (
        <SelectedCalendar
          deleteCalendar={deleteCalendar}
          calendar={selectedCalendar}
          back={() => setSc({})}
          refresh={refresh}
        />
      ) : (
        <>
          <AddCalendar />
          <AvailableCalendars
            availableCalendars={availableCalendars}
            deleteCalendar={deleteCalendar}
            setSc={setSc}
          />
        </>
      )}
    </>
  )
}

const SelectedCalendar = ({
  calendar = {},
  deleteCalendar = () => null,
  back = () => null,
  refresh = () => null,
}) => {
  if (calendar.id && !calendar.refresh_token) {
    return (
      <>
        Your calendar it not authenticated properly. Please{" "}
        <u
          style={{ cursor: "pointer" }}
          onClick={(e) => deleteCalendar(calendar.id)}
        >
          delete
        </u>{" "}
        it and retry.
      </>
    )
  }
  return <SetAvailability calendar={calendar} back={back} refresh={refresh} />
}
export default CalendarAvailabilityTemplate
