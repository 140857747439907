import React from "react"
import timeZones from "./timeZones"
import "./styles.less"

const AvailableCalendarsTemplate = ({
  availableCalendars = [],
  deleteCalendar = () => null,
  setSc = () => null,
}) => {
  if (availableCalendars.length === 0) return null
  return (
    <>
      <h3 className={"mt-5"}>Available calendars</h3>
      <div className="table-responsive">
        <table className="table table-hover table-lg mt-4">
          <tbody>
            {availableCalendars.length > 0 &&
              availableCalendars.map((calendar, idx) => {
                const tz = timeZones.find(
                  (tz) => tz.value === calendar.time_zone
                )
                return (
                  <tr key={idx}>
                    <td>
                      {calendar.name || "Default"} (
                      {calendar.calendar_type === "google"
                        ? "Google"
                        : "Office 365"}{" "}
                      calendar)
                    </td>
                    <td>{tz ? `${tz.label} (${tz.value})` : "N/A"}</td>
                    <td
                      className={"text-right"}
                      onClick={() => setSc(calendar)}
                    >
                      <button className={"btn btn-primary btn-sm"}>View</button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </>
  )
}
export default AvailableCalendarsTemplate
