import React, { useEffect, useState } from "react"
import { Button, Col, Input, Row } from "reactstrap"
import { useAuth } from "@auth/useAuth"
import { toast } from "react-toastify"

const CalendarAvailabilityTemplate = () => {
  // const { makeRequest } = useAuth()
  //
  // const [addNewCalendar, setAddNewCalendar] = useState(false)
  // const [calendarType, setCalendarType] = useState("")
  // const [calendarName, setCalendarName] = useState("")

  // const addCalendar = async () => {
  //   // add entry in the calendar table
  //   try {
  //     const res = await makeRequest("calendars/add", {
  //       calendarName,
  //       calendarType,
  //     })
  //   } catch (error) {
  //     console.log(error)
  //     toast.error("Failed to add the calendar. Please retry.")
  //   }
  //   // redirect the user to calendar URL
  // }

  return (
    <Row>
      <CalendarButtons />
    </Row>
  )
}

export default CalendarAvailabilityTemplate

const CalendarButtons = () => {
  const { makeRequest } = useAuth() || {}
  const [calendarName, setCn] = useState("")
  const [type, setType] = useState("")

  const createCalendarAndRedirect = async () => {
    if (!type || !calendarName) return
    try {
      const res = await makeRequest("calendars/add", {
        type,
        calendarName,
        origin: typeof window !== "undefined" ? window.location.origin : null,
      })
      if (res.authUrl && typeof window !== "undefined") {
        // redirect with state
        window.location.href = res.authUrl
      } else {
        toast.error("Failed to get the auth URL. Please retry.")
      }
    } catch (error) {
      console.log(error)
      toast.error("Failed to link the calendar. Please retry.")
    }
  }

  useEffect(() => {
    if (!type) setCn("")
  }, [type])
  return (
    <>
      {type ? (
        <>
          <Col md={"5"} xl={"7"} className={"text-right"}>
            <Input
              style={{ height: "46px" }}
              className={"d-inline-block"}
              placeholder={`Name of the ${
                type === "google" ? "google" : "office 365"
              } calendar`}
              autoFocus
              value={calendarName}
              onChange={(e) => setCn(e.target.value)}
            />
          </Col>
          <Col md={"7"} xl={"5"} className={"mt-3 mt-md-0 text-md-right"}>
            <Button
              color={"primary"}
              className={"ml-md-3"}
              onClick={createCalendarAndRedirect}
              disabled={!calendarName || !type}
            >
              Create
            </Button>
            <Button
              color={"secondary"}
              className={"ml-3"}
              onClick={() => setType("")}
            >
              Cancel
            </Button>
          </Col>
        </>
      ) : (
        <Col sm={"12"} className={"text-right"}>
          <div className={"d-md-flex align-items-center justify-content-end"}>
            <span className={" my-1"}>Connect a calendar: </span>
            <Button
              color={"primary"}
              className={"ml-3 my-1"}
              onClick={() => setType("microsoft")}
            >
              Office 365 calendar
            </Button>
            <Button
              color={"primary"}
              className={"ml-3 my-1"}
              onClick={() => setType("google")}
            >
              Google calendar
            </Button>
          </div>
        </Col>
      )}
    </>
  )
}
