import React from "react"
import CalendarAvailability from "@templates/CalendarAvailability"
import SEO from "@components/seo"

const CalendarAvailabilityPage = () => {
  return (
    <>
      <SEO title="Calendar availability" />
      <CalendarAvailability />
    </>
  )
}
export default CalendarAvailabilityPage
